exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lectures-kouza-tsx": () => import("./../../../src/pages/lectures/kouza.tsx" /* webpackChunkName: "component---src-pages-lectures-kouza-tsx" */),
  "component---src-pages-lectures-kyoshitsu-tsx": () => import("./../../../src/pages/lectures/kyoshitsu.tsx" /* webpackChunkName: "component---src-pages-lectures-kyoshitsu-tsx" */),
  "component---src-pages-lectures-ori-tsx": () => import("./../../../src/pages/lectures/ori.tsx" /* webpackChunkName: "component---src-pages-lectures-ori-tsx" */),
  "component---src-pages-link-index-tsx": () => import("./../../../src/pages/link/index.tsx" /* webpackChunkName: "component---src-pages-link-index-tsx" */),
  "component---src-pages-order-index-tsx": () => import("./../../../src/pages/order/index.tsx" /* webpackChunkName: "component---src-pages-order-index-tsx" */),
  "component---src-pages-pattern-design-index-tsx": () => import("./../../../src/pages/pattern-design/index.tsx" /* webpackChunkName: "component---src-pages-pattern-design-index-tsx" */),
  "component---src-pages-pattern-design-microcms-pages-pages-id-tsx": () => import("./../../../src/pages/pattern-design/{microcmsPages.pagesId}.tsx" /* webpackChunkName: "component---src-pages-pattern-design-microcms-pages-pages-id-tsx" */),
  "component---src-pages-store-index-tsx": () => import("./../../../src/pages/store/index.tsx" /* webpackChunkName: "component---src-pages-store-index-tsx" */),
  "component---src-pages-teori-movie-tsx": () => import("./../../../src/pages/teori/movie.tsx" /* webpackChunkName: "component---src-pages-teori-movie-tsx" */),
  "component---src-pages-teori-oriito-tsx": () => import("./../../../src/pages/teori/oriito.tsx" /* webpackChunkName: "component---src-pages-teori-oriito-tsx" */),
  "component---src-pages-teori-trivia-index-tsx": () => import("./../../../src/pages/teori-trivia/index.tsx" /* webpackChunkName: "component---src-pages-teori-trivia-index-tsx" */),
  "component---src-pages-teori-trivia-microcms-pages-pages-id-tsx": () => import("./../../../src/pages/teori-trivia/{microcmsPages.pagesId}.tsx" /* webpackChunkName: "component---src-pages-teori-trivia-microcms-pages-pages-id-tsx" */),
  "component---src-pages-yama-index-tsx": () => import("./../../../src/pages/yama/index.tsx" /* webpackChunkName: "component---src-pages-yama-index-tsx" */),
  "component---src-pages-yatsu-trivia-index-tsx": () => import("./../../../src/pages/yatsu-trivia/index.tsx" /* webpackChunkName: "component---src-pages-yatsu-trivia-index-tsx" */)
}

